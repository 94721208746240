import { createContext, useContext, useEffect, useRef, useState } from 'react'

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'altcha-widget': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
                ref?: React.RefObject<HTMLElement>
                challengeurl: string
                floatinganchor?: string
                delay?: number
                floating?: boolean
                auto?: string
            }
        }
    }
}

interface CaptchaProviderProps {
    children: React.ReactNode
}

interface CaptchaContextValues {
    captcha: string | null
    setCaptcha: (payload: string | null) => void
}

export const CaptchaContext = createContext<CaptchaContextValues>({ captcha: null } as CaptchaContextValues)

export function CaptchaProvider({ children }: CaptchaProviderProps) {
    const [captcha, setCaptcha] = useState<string | null>(null)
    return <CaptchaContext.Provider value={{ captcha, setCaptcha }}>{children}</CaptchaContext.Provider>
}

interface CaptchaWidgetProps {
    floating?: boolean
}

/**
 * This component renders the captcha widget and sets the captcha value in the context.
 *
 * IMPORTANT: This component should be rendered only as child of a form tag!
 */
export function CaptchaWidget({ floating = true }: CaptchaWidgetProps) {
    const { setCaptcha } = useContext(CaptchaContext)
    const widgetRef = useRef<HTMLElement>(null)

    useEffect(() => {
        const handleStateChange = (ev: Event | CustomEvent) => {
            if ('detail' in ev) {
                setCaptcha(ev.detail.payload || null)
            }
        }

        const { current } = widgetRef
        if (current) {
            current.addEventListener('statechange', handleStateChange)
            return () => current.removeEventListener('statechange', handleStateChange)
        }
    }, [widgetRef])

    return <altcha-widget ref={widgetRef} challengeurl={`${process.env.NEXT_PUBLIC_PUBLIC_API_URL}/captcha/challenge`} delay={500} floating={floating} />
}
