import { RequestLoginCodePayload } from '@tebuto/api/clients/models/RequestLoginCodePayload'
import { useClientsApiMutation } from '@tebuto/functions'
import { useRouter } from 'next/navigation'
import { useContext } from 'react'
import { LoginStepContext, SendCodeFormValues } from './LoginForm'
import PAGE_TEXTS from './translations'

// Mutations

export function useRequestCode(startTimer: (seconds: number) => void) {
    const { setStep, setEmail } = useContext(LoginStepContext)

    const { trigger: requestCode, isMutating } = useClientsApiMutation(
        'requestCode',
        (api, values: RequestLoginCodePayload) =>
            api.auth.requestLoginCode(values).then(() => {
                setEmail(values.email)
                setStep('sendCode')
                startTimer(60)
            }),
        { successMessage: PAGE_TEXTS.loginForm.sendCode.requestNewCodeDialog.newCodeSent }
    )
    return { requestCode, isMutating }
}

export function useSendCode(setError: (error: string) => void) {
    const router = useRouter()
    const { trigger } = useClientsApiMutation(
        'sendCode',
        (api, values: SendCodeFormValues) =>
            api.auth
                .login({ code: values.otp })
                .then(() => {
                    localStorage.removeItem('loginStep')
                    localStorage.removeItem('email')
                    router.push('/dashboard')
                })
                .catch(() => setError(PAGE_TEXTS.loginForm.sendCode.requestNewCodeDialog.invalidCode)),
        { successMessage: null }
    )
    return trigger
}
